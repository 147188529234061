




















































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component({
  components: {
    Location: () => import('@/components/base/InsLocation.vue'),
    InsCmsParentCat: () => import('@/components/business/cms/InsCmsParentCat.vue'),
    InsCmsCat1: () => import('@/components/business/cms/InsCmsCat1.vue'),
    InsCmsCat2: () => import('@/components/business/cms/InsCmsCat2.vue'),
    InsCmsCat3: () => import('@/components/business/cms/InsCmsCat3.vue'),
    InsCmsCat4: () => import('@/components/business/cms/InsCmsCat4.vue'),
    InsCmsCat5: () => import('@/components/business/cms/InsCmsCat5.vue'),
    InsCmsCat6: () => import('@/components/business/cms/InsCmsCat6.vue'),
    InsCmsCat7: () => import('@/components/business/cms/InsCmsCat7.vue'),
    InsCmsCat8: () => import('@/components/business/cms/InsCmsCat8.vue'),
    InsCmsCat9: () => import('@/components/business/cms/InsCmsCat9.vue'),
    InsCmsCat10: () => import('@/components/business/cms/InsCmsCat10.vue'),
    InsCmsCat11: () => import('@/components/business/cms/InsCmsCat11.vue'),
    InsCmsCat12: () => import('@/components/business/cms/InsCmsCat12.vue'),
    InsCmsCat13: () => import('@/components/business/cms/InsCmsCat13.vue'),
    InsCmsCat14: () => import('@/components/business/cms/InsCmsCat14.vue'),
    InsCmsTwoSwiper: () => import('@/components/business/cms/InsCmsTwoSwiper.vue')
  }
})

export default class InsCatDetail extends Vue {
  cmsCategory: object = {}; // cms下所有目录的信息
  catDetail: object = {}; // cms下单个目录的信息
  cmsCatTree: object[] = []; // cms目录
  contentList: object[] = []; // cms内容列表
  catId: number = 0; // Tree点击获取内容列表的目录id
  PageStyle: string = '0'; // catDetail页面类型

  // 根据设备类型获取CMSCategory信息
  getCategoryByDevice () {
    this.$Api.cms
      .getCategoryByDevice({ CatId: this.id, IsMobile: this.isMobile })
      .then(result => {
        console.log(result.Children, '当前catDetail Children数据');
        this.cmsCategory = result;
        this.PageStyle = result.PageStyle;
        // let seoData = require('@/sdk/common/SeoData');
        document.title = result.Name;
      })
      .catch(error => {
        console.log(error, 'error');
        this.$message({
          message: error,
          type: 'error'
        });
      });
  }
  // 获取cms该id下所有的Category
  getCategoryTree () {
    this.$Api.cms.getCategoryTree({ id: this.id }).then(result => {
      if (result && result.length) {
        this.cmsCatTree = result;
        this.catId = result[0].Id;
        this.getContentsByCatId();
      } else {
        this.getContentsByCatId();
      }
    });
  }
  // 获取cms该id下所有的cms
  getContentsByCatId () {
    let catId = this.catId || this.id;
    this.$Api.cms.getContentsByCatId(catId, 1, 20).then(result => {
      this.contentList = result.Data;
      console.log(this.contentList, '文章列表');
    });
  }
  // 获取 Category 和所有 SubCategory 的 cms 列表
  getSubCatContents () {
    this.$Api.cms
      .getSubCatContents({ CatId: this.id, IsMobile: this.isMobile })
      .then(result => {
        console.log(result, ' 获取所有 SubCategory 的 cms 列表');
        this.contentList = result.Data;
      });
  }
  // 树形控件选择的cms目录改变
  changeCatSelect (Id) {
    this.catId = Id;
    this.getContentsByCatId();
  }
  get id () {
    console.log(this.$route.params.id, '当前id');
    return this.$route.params.id;
  }

  get isMobile () {
    return this.$store.state.isMobile;
  }
  get fromSearch () {
    return this.$route.query.fromSearch;
  }
  mounted () {
    this.getCategoryByDevice();
  }

  @Watch('id', { deep: true })
  onKeyChange () {
    this.catDetail = {};
    this.cmsCatTree = [];
    this.contentList = [];
    this.catId = 0;
    this.cmsCategory = {};
    this.getCategoryByDevice();
  }

  @Watch('isMobile', { deep: true })
  onMediaChange () {
    this.catDetail = {};
    this.cmsCatTree = [];
    this.contentList = [];
    this.catId = 0;
    this.cmsCategory = {};
    this.getCategoryByDevice();
  }
}
